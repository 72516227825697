<template>
    <a-modal
        :visible="visible"
        :closable="false"
        :centered="true"
        :title="null"
        :footer="null"
        width="320px"
    >
      <div style="font-size: 16px; font-weight: bold;">
        <a-icon type="exclamation-circle" theme="filled" style="color: #FAAD14;font-size: 18px;"/>
        是否删除该信息？
      </div>
      <div style="display: flex; justify-content: center;margin: 30px 0;">
        删除该标签将会解除，动态与标签的关系，请谨慎操作
      </div>
      <div>
        <a-button style="width: 180px;" type="primary" @click="confirmStatus">确认</a-button>
        <a-button style="margin-left: 15px;" @click="$emit('cancelStatus');">取消</a-button>
      </div>
    </a-modal>
</template>

<script>

export default {
  name: "DeleteTagModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    confirmStatus(val) {
      console.log('use value', val)
      this.$emit('confirmStatus');
    },
  },
};
</script>
