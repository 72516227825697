import request from '@/utils/request'

const baseUrl = `${process.env.VUE_APP_API_API}/admin`;
/**
 * 标签分页列表
 */
export function get (data) {
  return request({
    method: 'post',
    url: `${baseUrl}/tab/queryTabPage`,
    data
  })
}

/**
 * 标签新增或编辑
 */
export function add (data) {
  return request({
    method: 'post',
    url: `${baseUrl}/tab/addOrUpdate`,
    data
  })
}

/**
 * 标签新增或编辑
 */
export function deleteById (data) {
  return request({
    method: 'post',
    url: `${baseUrl}/tab/deleteById`,
    data
  })
}
