<template>
  <ak-container card :bodyStyle="{ padding: 0 }" class="ak-card-table">
    <a-card style="width: 96%; margin: 10px auto; margin-bottom: 20px; padding-bottom: 0;">
      <a-row :gutter="24">
        <a-col :span ="16">
          <a-button type="primary" @click="$refs.modalTag.showModal('level1')"
            >新增兴趣标签
          </a-button>
        </a-col>
        <a-col :span ="8" style="text-align: right">
          <a-form-model
            ref="form"
            class="ak-query-filter"
            :model="queryFilters"
            :colon="false"
          >
            <a-form-model-item label="" prop="tabName">
              <a-input-search
                v-model="queryFilters.tabName"
                placeholder="请输入标签名字"
                enter-button=" 查询 "
                size="large"
                @search="onSearch"
              />
            </a-form-model-item>
          </a-form-model>
        </a-col>
      </a-row>
    </a-card>

    <a-table :pagination='pagination'
              :columns="columns"
              :data-source="tableList"
              :scroll="{ x: 100 }"
              :rowKey="(record) => record.id"
              @change="handleTableChange"
              :loading="loading"
              :indentSize="20"
              style="width:96%; margin: 0 auto"
      >
      <!-- 状态 -->
      <template #status="text">
        <span v-if="text === 1">正常</span>
        <span v-else class="redColor">禁用</span>
      </template>

      <!-- 操作 -->
      <template #action="text, record">
        <a v-if="record.level===1" @click="$refs.modalTag.showModal('level2',record)">新增二级标签</a>
        &nbsp;
        <a @click="$refs.modalTag.showModal('edit',record)">编辑</a>
        &nbsp;
        <a @click="handleDelete(record)">删除</a>
      </template>
    </a-table>

    <modal-tag ref="modalTag" @end="queryData" />

    <!-- 删除 弹窗-->
    <DeleteTagModal
      :visible ="statusVisible"
      @confirmStatus ="deleteTag"
      @cancelStatus ="statusVisible = false"
    />
  </ak-container>
</template>

<script>
import * as TagApi from '@/api/content/tag.api.js'
import ModalTag from './modal/modal-tag'
import DeleteTagModal from './modal/index'

export default {
  data () {
    return {
      tableList: [],
      pagination: {
        total: 0,
        pageSize: 10, // 每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"], // 每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
      },
      loading: false,
      // 查询参数
      // 查询参数
      queryFilters: {
        page: 1,
        pageSize: 10
      },
      // 表头
      columns: [{
        title: '兴趣标签名称',
        dataIndex: 'tabName',
        scopedSlots: { customRender: 'tabName' },
        align: 'left'
      }, {
        title: '排序',
        dataIndex: 'order',
        scopedSlots: { customRender: 'order' }
      }, {
        title: '最后修改时间',
        dataIndex: 'updatedTime',
        defaultSortOrder: 'descend', // 默认上到下为由大到小的顺序
        sorter: (a, b) => { return a.updatedTime > b.updatedTime ? 1 : -1 }
      }, {
        title: '修改人',
        dataIndex: 'updatedBy'
      }, {
        title: '操作',
        dataIndex: 'action',
        width: 200,
        scopedSlots: { customRender: 'action' }
      }],
      id: null,
      statusVisible: false,
      loadingStatus: false
    }
  },
  components: {
    ModalTag,
    DeleteTagModal
  },
  mounted(){
    this.queryData()
  },
  methods: {
    // 表格分页条件、筛选条件、排序条件发生变化后刷新表格数据
    handleTableChange(pagination, filters, sorter) {
      let that = this;
      that.sortedInfo = sorter;
      const pager = { ...that.pagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      that.pagination = pager;
      this.queryFilters.page = pagination.current;
      this.queryFilters.pageSize = pagination.pageSize;
      that.queryData()
    },
    // 加载数据方法
    queryData () {
      this.loading = true
      TagApi.get({...this.queryFilters})
        .then((res) => {
          this.loading = false
          if (res.data) {
            let obj = JSON.parse(res.data);
            this.tableList = obj.list
            this.pagination.total = obj.total
          }
        })
        .catch(e => {
          this.message = e.message || '查询错误'
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 拖动结束，松开鼠标
    onDrop (sourceItem, targetItem, isDrop) {
      console.log(sourceItem, targetItem, isDrop)
    },
    handleDelete(row) {
      this.id = row.id
      this.statusVisible = true
    },
    // 删除
    deleteTag() {
      const param = {
        id: this.id,
      }
      this.loadingStatus = true
      TagApi.deleteById(param)
        .then((res) => {
          const { code } = res
          this.loadingStatus = false
          if (code === 0) {
            this.$message.success('删除成功')
            this.statusVisible = false
            this.queryData()
          }
        })
        .catch(e => {
          this.message = e.message || '删除失败'
        })
        .finally(() => {
          this.loading = false
        })
    },
    onSearch (searchValue) {
      this.pagination.current=1;
      this.queryFilters.page=1;
      console.log('use value', searchValue)
      this.queryData()
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-card-body {
  padding: 18px 18px 8px 18px;
}

/deep/ .ant-col-xl-8 {
  padding-left: 0 !important;
}

/deep/ .ant-table-tbody > tr > td {
  padding-left: 10px !important;
}
</style>
