<template>
  <a-modal
    :width="width"
    :visible="visible"
    :title="title"
    :after-close="onClosed"
    :closable="false"
    destroyOnClose
    @ok="onHideModal"
    :footer="null"
  >
    <a-form-model
    ref="refForm"
    :model="formState"
    :label-col="{ span: 4 }"
    :wrapper-col="{ span: 16 }"
    autocomplete="off"
    :rules="rules"
  >
    <a-form-model-item label="标签图片" prop="icon">
      <a-upload
        list-type="picture-card"
        class="avatar-uploader"
        :show-upload-list="false"
        :before-upload="handleBeforeUpload"
        @change="handleChange">
        <img v-if="imgurl" :src="imgurl" alt="avatar" style="width:100px;height:100px" />
        <div v-else>
          <a-icon type='loading' v-if="loading"/>
          <a-icon type='plus' v-else/>
          <div class="ant-upload-text">上传</div>
        </div>
      </a-upload>
    </a-form-model-item>

    <a-form-model-item
      label="兴趣标签"
      prop="tabName"
    >
      <a-input v-model="formState.tabName" />
    </a-form-model-item>

    <a-form-model-item
      label="排序"
      prop="order"
    >
      <a-input-number v-model="formState.order" :min="1" :max="100000" />
      <span style="margin-left:10px;">数字越小排在前面，数字越大排在后面</span>
    </a-form-model-item>

    <a-row class="form-footer">
      <a-button style="margin-left: 10px" class="btn-cancel" @click="onHideModal">取消</a-button>
      <a-button type="primary" @click="onSubmit" class="btn-sure">确定</a-button>
    </a-row>
  </a-form-model>
  </a-modal>
</template>

<script>
import * as TagApi from '@/api/content/tag.api.js'
import * as OssClient from "@/components/AliOss/OssClient";

const rules = {
  icon: [{
    required: true, message: '标签图片不能为空'
  }],
  tabName: [{
    required: true, message: '兴趣标签不能为空'
  }, {
    max: 10, message: '标签不能超过10个字符'
  }],
  order: [{
    required: true, message: '排序不能为空'
  }]
}

export default {
  data () {
    return {
      width: 560,
      visible: false,
      title: '新增兴趣标签',
      fileList: [],
      formState: {
        id: 0,
        icon: '',
        tabName: '',
        level: 1,
        tabParentId: 0,
        order: 1,
        status: 1,
        validFlag: 1,
        createdBy: '',
        createdTime: '',
        updatedBy: '',
        updatedTime: '',
        remark: '',
        comment: ''
      },
      imgurl: '',
      loading: false,
      rules
    }
  },
  methods: {
    // 提给外部调用的方法
    showModal (type, row) {
      console.log('1', type, row)
      row = row || {};
      if (type === 'level1') {
        this.title = '新增兴趣标签'
        this.formState.id = 0;
        this.formState.icon = '';
        this.formState.tabName = '';
        this.formState.level = 1;
        this.formState.tabParentId = 0;
        this.formState.order = 1;
      } else if (type === 'level2') {
        this.title = '新增二级兴趣标签'
        this.formState.id = 0;
        this.formState.icon = '';
        this.formState.tabName = '';
        this.formState.level = 2;
        this.formState.tabParentId = row.id || 0;
        this.formState.order = 1;
      } else if (type === 'edit') {
        this.title = '编辑兴趣标签'
        this.formState.id = row.id || 0;
        this.formState.icon = row.icon || '';
        this.formState.tabName = row.tabName || '';
        this.formState.level = row.level || 1;
        this.formState.tabParentId = row.tabParentId || 0;
        this.formState.order = row.order || 1;
      }
      this.imgurl = "";
      if (this.formState.icon) {
        this.imgurl = this.$const.OssImagePrefix + '/' + this.formState.icon;
      }
      this.visible = true
    },
    // 关闭弹框
    onHideModal () {
      this.visible = false
    },
    // 完全关闭弹框后的回调，可用来重置数据
    onClosed () { },
    /**
     * 把oss上传的结果对象转换成upload组件用的对象结构
     * @param ossFileResult oss返回结构 {fileName,name:相对路径, url, res:{}}
     */
    convertOssToUpload(ossFileResult) {
      let uploadResult = {
        uid: ossFileResult.path, // upload组件记录唯一区分
        url: ossFileResult.url,
        status: 'done',
        name: ossFileResult.name, // 上传时候传入的fileName
        fileName: ossFileResult.name,
        uri: ossFileResult.path, // 上传之后的url的相对路径
        sort: 0, // 排序字段(默认就是数字下标排序)
        id: null, // id(动态：内容id是1对多),
        flag: 1 // flag :操作标记(1:新增 2:删除 3:无需处理)
      }
      return uploadResult
    },
    /**
     * 点击预览
     * @param file
     * @returns {Promise<void>}
     */
    async handlePreview(file) { },
    /**
     * 上传前处理
     * true =>file.status = 'uploading' 进入onchange
     * false =>file.status = undefined' 进入onchange，执行一次
     * reject() 则停止进入 onchange
     * resolve可以继续onchange
     */
    handleBeforeUpload(file) {
      // 开始设置上传文件
      console.log("【发动态】1.上传图片开始...", file)
      OssClient.uploadFile({
        bizType: "trend",
        type: "file",
        fileName: file.name,
        targetName: new Date().getTime() + "_" + file.name,
        file: file
      }).then(result => {
        console.log("【发动态】2.上传图片完成...", result)
        let fileResult = this.convertOssToUpload(result)
        this.fileList = [fileResult];
        this.formState.icon = fileResult.name;
        this.imgurl = fileResult.url;
      }).catch(err => {
        console.log("上传处理失败：", err)
        this.$modal.alertError("上传处理失败：" + err)
      })
      return false;
    },
    /**
     * 移除文件
     */
    handleRemove(file) { },
    /**
     * 文件变更时间，进行上传处理
     */
    async handleChange({file, uploadFileList}) {
      if (!file.status) {
        return
      }
      if (file.status === "uploading") {
        this.$message.success(`${file.name} 正在上传`);
      } else if (file.status === "done") {
        this.$message.success(`${file.name} 上传成功`);
      } else if (file.status === "error") {
        this.$message.error(`${file.name} 文件上传失败`);
      }
    },
    beforeUpload (file) { },
    onSubmit () {
      console.log(this.$refs["refForm"])
      this.$refs["refForm"].validate((valid) => {
        if (valid) {
          this.loading = true
          this.formState.id = this.formState.id === 0 ? null : this.formState.id;
          TagApi.add(this.formState)
            .then(() => {
              this.$message.success('提交成功')
              this.$emit("end", true)
              this.onHideModal()
            })
            .finally(() => {
              this.loading = false
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>
